import React from 'react'
import { Link } from 'react-router-dom'
import { Usegetvalue } from '../contexrt/Myprovider'
import Home from '../Home'
import './login.css'
const Regester= () => {
    const regester = Usegetvalue()
  return (
    <div className='container'>

 <div className='form-container'>
   <form>
    <div className='form-divs'>
    <label>Magaca</label>
    <input type ='text' placeholder ='magacaaga ' name='user' required onChange={regester.regesterhandler}/>
    </div>
    <div className='form-divs'>
    <label>Password </label>
    <input type ='text' placeholder ='magacaaga ' name='pass' required onChange={regester.regesterhandler}/> 
    </div>
    <div className='form-divs'>
    <label>Hubin  </label>
    <input type ='text' placeholder ='magacaaga '  name ='hubin' required onChange={regester.regesterhandler}/> 
    </div>
    <button onClick={regester.regester}>
        <Link to ='login'>Regester </Link>
   </button>
   </form>
  
   </div>
    </div>
  )
}

export default Regester