import React, { useEffect,useRef, useState } from 'react'
import ReactToPrint from 'react-to-print'
import {Link} from "react-router-dom"
import { Usegetvalue } from '../contexrt/Myprovider'
import Printing from '../printfolder/Printing';
import "./milkiil.css"


import "./milkiil.css"

const Listmilkile = () => {


  const componentRef = React.createRef();
  const [saved,setSaved]=useState(false)
  
  const datacontext= Usegetvalue();
  console.log("milke list ",datacontext.milkilelist)

  return (

    
    <div className='Listmilkile'>
     
  <table>

<thead>
  <tr>
    <th>magaca</th>
    <th className='td-isticmal'>lacagta</th>
    <th>korarka</th>
    <th className='td-isticmal'>isticmal</th>
    <th className='td-isticmal'>zako</th>
    <th>rasulmalka cusub</th>

    </tr>
</thead>
<tbody>
{
  datacontext.milkilelist.map((item,index)=>{
// {magaca,rasl_hore,instasiyaday,sako_qof,istcaml,korosandle}
return <tr key ={index}> 
    <td className='td-data'>{item.magaca}</td>
    <td className='td-data td-isticmal'>{item.raslqof}</td>
    <td className='td-data'>{item.instasiyaday}</td>
    <td className='td-data td-isticmal'>{item.isticmal}</td>
    <td className='td-data td-isticmal'>{item.sako_qof}</td>
    <td className='td-data'>{item.korosandle}</td>
    <td><button className='td-isticmal' onClick={()=>
      datacontext.waxkabedel(index)
    }> edit</button></td>
    <td><button className='td-isticmal' onClick={()=>datacontext.iskatirtir(index)}> delete</button></td>

    <td><button  className ='td-isticmal'onClick={()=>datacontext.printusers({item},index)}> <Link to ="userprint">print</Link></button></td>
</tr>

  }
  )
}



</tbody>
    </table>

    <div className='milkile-total' style={{display:"flex",justifyContent:"space-between",width:'70%'}}>
   
 <h2> total </h2> <span>$ 
  
  
  {
    datacontext.milkilelist.length===0? 
     "000.0"
    :datacontext.milkilelist.reduce((total,item)=>{
        return total=total+parseFloat(item.korosandle)
    },0.0)
  
  } 
  
  -
   </span>

  <button className='td-isticmal' onClick={datacontext.keydi}>Keydi xisaabta </button>
    </div>
  


   

           <Link to ="print"><button>Show reporting</button></Link>
         {/*<Printing malkiilelist={malkiilelist}/>*/}       
        
</div>


  )


}

export default Listmilkile