import React from 'react'
import { useContext } from 'react';
import PrinterHeader from '../headfoot/PrinterHeader';
import { mycontext, Usegetvalue } from '../contexrt/Myprovider';
import "./login.css"
import Footer from '../headfoot/Footer';
const Userprinter = () => {
  //{magaca,rasl_hore,instasiyaday,sako_qof,istcaml,korosandle}
    
  const values = Usegetvalue()
  const {magaca,raslqof,instasiyaday,sako_qof,isticmal,korosandle} = values.userData
  //console.log("inside user prnter ",values.userData)
    
  return (
  
    <div className='Listmilkile'>
  <section >
    <PrinterHeader/>
      </section>
      <section>

 <table>
<thead>
<tr>
    <td>magaca</td>
    <td>lacagta</td>
    <td>korarka</td>
    <td>isticmal</td>
    <td>zako</td>
    <td>rasulmalka cusub</td>
    
</tr>
</thead>
<tbody>

{/*magaca,rasl_hore,instasiyaday,sako_qof,istcaml,korosandle*/}
   <tr> 
   <td className='td-data'> {magaca}</td>
    <td className='td-data'>{raslqof}</td>
    <td className='td-data'>{instasiyaday}</td>
    <td className='td-data'>{isticmal}</td>
    <td className='td-data'>{sako_qof}</td>
    <td className='td-data'>{korosandle}</td>
</tr>

</tbody>
    </table>
 
    </section>

    <section className='footer'>
        <Footer/>
    </section>
</div>
  )
}

export default Userprinter;