import React, { createContext, useContext, useState } from "react";



export const mycontext=createContext({} )

export function Usegetvalue(){
return useContext(mycontext)

}
const  Myprovider =({children,inistates})=>{

    
        
return (
<div>
<mycontext.Provider value={inistates}>

    {children}
</mycontext.Provider> 
</div>
)
}

export default Myprovider;