
import './App.css';
import { useEffect, useRef,useState } from 'react';
import Myprovider, { Usegetvalue } from './components/contexrt/Myprovider';
import Home from "./components/Home"
import { BrowserRouter,Routes,Route } from 'react-router-dom';

import Printing from './components/printfolder/Printing';
import Userprinter from './components/printfolder/Userprinter';
import Login from './components/login/Login';
import Regester from './components/login/Regester';



function App() {

  

  const [printuserData,setUserDataPrint]=useState([])
  const[xisabbilow,setXisabilow]=useState(()=>{

    const data1 = JSON.parse(localStorage.getItem('storage1'))
     const initialValue = data1;

     console.log("stored data ",data1)
     return initialValue || []
 })
  const [myid,setMyid]=useState(0)
  const [saved,setSaved] = useState(false);
  const [raslumal,setRasulmal]=useState(1)
  const [raslmaldanbe,setRsmdanbe]=useState(0);
  const [korka,setKorar]=useState(1);
  const [boqolkiba,setMsetBoqolkiiba]=useState(1);
  const [sako,setSako]=useState(0)
  const[kajardeynqarsh,setKajarqarshdeyn]=useState(100)
  const [rasmalsakokahor,setSakoKahore]=useState(0)
  const [qofboqlkiiba,setQofbolkiiba]=useState()
// qeybint handitada
const [magaca,setMagaca]=useState('')
const [raslqof ,setRsmQof]=useState(1)
const [isticmal,setIsticmal]=useState(0);
const [instasiyaday,setIntasiyada]=useState(0);
const [sako_qof,setSkoqof]= useState(1);
  const [mydata,setMydata]= useState(()=>{



     const data = JSON.parse(localStorage.getItem('storage'))
      const initialValue = data;

      console.log("stored data ",data)
      return initialValue || []
  })
  const [korosandle,setKororsandle]=useState(0)


  const [qarash,setQarash] = useState(0)
  const [celin,setCelin]= useState(0)
  const [totalqarsh,setQarashdeyn]=useState(0)
  const [deynkadib,setDeynkadib]=useState(0)
  const [lacagtanadiif,setLacgNadifah]= useState(0)
  const [faaiido,setFaaiido]=useState(0)
  const [finalboqolkiiba,setFinalboqolkiba]=useState(0)
  const [ralmalcusub,setRcusub]=useState(0)

/*  ras_hore
    ras_danbe
    ras_korka
    ras_boqolkiiiba
    ras_sako
    ras_guud
 
*/
const raslumlalfunc=(e)=>{
  e.preventDefault();
  
    if(e.target.name ==='ras_hore')
        setRasulmal(()=>parseInt(e.target.value))
  
    if(e.target.name==='ras_danbe')
       setRsmdanbe(()=>parseInt(e.target.value))
   
}


const qeybtintafaidafunc=(e)=>{
  e.preventDefault();

  if(e.target.name ==='magaca')
     setMagaca(()=>e.target.value)
  
  if(e.target.name==='rasmal_qof')
       setRsmQof(()=>e.target.value)

  if(e.target.name==='isticmal')
     setIsticmal(parseInt(e.target.value))
  
 

}

const qarshdeynfun=(e)=>{
 if(e.target.name ==='qarash')
     setQarash(e.target.value)

  else{
    setCelin(e.target.value)
  }
}
useEffect(()=>{
  let result =parseFloat(raslmaldanbe-raslumal);
  let koordhid = ((result/raslumal)*100).toFixed(2);
  setKorar(result)
  setMsetBoqolkiiba(koordhid)
  setSakoKahore(parseFloat(raslmaldanbe).toFixed(2))
 
 // setXisabilow([raslumal,raslmaldanbe,result,boqolkiba,sako,sakokaidb]);
 
  console.log(" effect one  ") 
},[raslmaldanbe,raslumal])


useEffect(()=>{
  /* let koroqofkiiba = parseFloat(raslmaldanbe - raslqof).toFixed(2)
  let koordh = (koroqofkiiba*qofboqlkiiba)/100;
 

  setRcusub(ralmalcuB)
  setIntasiyada(parseFloat(instasiyaday).toFixed(2))
  let sako_qofk= (parseFloat(ralmalcusub)-parseFloat(isticmal))*0.01
  setSkoqof(sako_qofk.toFixed(2))
 
  setSakoKahore(parseFloat(raslmaldanbe)-parseFloat(sako).toFixed(2))
  let kororsandle  = parseFloat(ralmalcusub) - parseFloat(sako_qofk);

  //setSkoqof(parseFloat(deynkadib*0.01).toFixed(2))
  
  setSaved(false)

 localStorage.setItem('storage',JSON.stringify(mydata)); */

let siyaado =parseFloat(raslqof)*parseFloat(qofboqlkiiba)/100
let ralmalcuB = parseFloat(siyaado)+parseFloat(raslqof);
let sako_qofk= (parseFloat(ralmalcuB)-parseFloat(isticmal))*0.01
let kororsandle  = parseFloat(ralmalcuB) - (parseFloat(sako_qofk)+parseFloat(isticmal));

 setSkoqof(sako_qofk.toFixed(2))
 setIntasiyada(siyaado.toFixed(2))
 setKororsandle(parseFloat(kororsandle).toFixed(2))

 localStorage.setItem('storage',JSON.stringify(mydata)); 

 console.log("effect two ",raslqof)


},[raslqof,isticmal,deynkadib,finalboqolkiiba,instasiyaday])

// hanltiilataasha 
const kudarmilkile=(e)=>{
 e.preventDefault();

 setMydata([...mydata, {magaca,raslqof,instasiyaday,sako_qof,isticmal,korosandle}])
 //{const storing = milkilelist.length ===0? localStorage.getItem('storage'): 
   
//}
  setSakoKahore(0)
  setMagaca('')
  setIsticmal(1)
  setSkoqof(0)
  setRsmQof(0)
  setIntasiyada(0)
  setKororsandle(1) 
}

const iskatirtir=(id=0)=>{

  console.log(" ited to bed delted index is ",id)
  setMyid(id)

}

const printusers =({item},index)=>{
  setUserDataPrint(item)

  console.log("waan daabaci doonaa insha alha",index,item)

}
const waxkabedel =(id)=>{
  console.log(" ited to bed delted index is ",id)

}
// waa keyding to msysql 
const keydi=()=>{

setSaved(true)
  }

  const rasulmafn=(e)=>{
       console.log(" nama waa rasul malaa ",xisabbilow)
       localStorage.setItem('storage1',JSON.stringify(xisabbilow));
  }

  useEffect(()=>{
  let qarshdeyn = parseFloat(celin)+parseFloat(qarash)
  setQarashdeyn(qarshdeyn)
     
  setDeynkadib(parseFloat(raslmaldanbe-qarshdeyn))
  let sakaat =(parseFloat(deynkadib)*0.01).toFixed(2)
  setSako(parseFloat(sakaat))
  
  setLacgNadifah(parseFloat(deynkadib)-parseFloat(sakaat))
  const faadio =lacagtanadiif-raslumal
  setFaaiido(faadio.toFixed(2))
  let lstboqlkiiba = (faadio/raslumal)*100
  setFinalboqolkiba(lstboqlkiiba.toFixed(2))
  let qofblkba = ((deynkadib-parseInt(raslumal))/raslumal)*100
  setQofbolkiiba(qofblkba.toFixed(2))
  console.log(" qarash iyo soo celin faa ii do ",faadio,lacagtanadiif,raslumal)
  },[qarash,celin,totalqarsh,raslmaldanbe,raslumal])


  const [isloged ,setLoged]= useState(false)
  const [username,setUsername]=useState('')
  const [passing,setPassing]=useState('')
  const [uspad,setUsp]=useState([]);
  const [iregester,setIsregester]=useState(false)
  //
  const submitlogin=(e)=>{
    e.preventDefault();
       setLoged(true)
  }
  const regesterhanderler=(e)=>{
e.preventDefault()
const {name,value}= [e.target.name,e.target.value]
setUsp({...uspad,[e.target.name]:e.target.value})

console.log(uspad)
  }
  useEffect(()=>{

  console.log(uspad)

  },[uspad])

  const regester=()=>{
             setIsregester(true)
   }

// state 
const initilastate ={
rasulmal:raslumal,
raslmaldanbe:raslmaldanbe,
korka:korka,
boqolkiba:boqolkiba,
sako:sako,

rasmalsakokahor:rasmalsakokahor,
milkiile:'',

magaca:magaca,
raslqofk:raslqof,
isticmal:isticmal,
sako_qof:sako_qof,
qofboliiba:qofboqlkiiba,
instasiyaday:instasiyaday,

korosandle:korosandle,
milkilelist:mydata,
xisaabhoredata:xisabbilow,
userData:printuserData,
totalqarshdeyn:totalqarsh,
deynkadib:deynkadib,
lacagtanadiifah:lacagtanadiif,
faaiido:faaiido,
finalboqolkiiba:finalboqolkiiba,
raslumlalfunc:raslumlalfunc,
qeybtintafaidafunc:qeybtintafaidafunc,
kudarmilkile:kudarmilkile,
iskatirtir:iskatirtir,
waxkabedel:waxkabedel,
printusers:printusers,
keydi:keydi,
rasulmalfun:rasulmafn,
qarshdeynfun:qarshdeynfun,
login:submitlogin,
regesterhandler:regesterhanderler,
regester:regester

}
/*
  return (
    <BrowserRouter>
    <div className="App">
     <h1>xisaabinta dukaamada </h1>
     <Myprovider inistates={initilastate}>
<section>
  <h1> Lacagta sanidkuu hore lagu shaqeeyey iyo waxa ku hordhay</h1>
<Rasulmal/>

</section>
     <section>
      <h1> qeybinta lacagta sanadkanan iyo wixii la faaiidya </h1>
     <Milkiilayasha/>
     <Listmilkile/>
     </section>
     <section>
     <h2> lacagata sanada soo socod lagu xisaabtamid doono insha allah</h2>
         
          <Sanadanbe/>
     </section>
     </Myprovider>

     <section>
        {saved && <Server datatobesaved={milkilelist}/>}
     </section>
    </div>
 */
return(
  <Myprovider inistates={initilastate}>
 <BrowserRouter>
     <Routes>
      {iregester?
      <Route path ="/login" element ={<Login/>}/>:
      <Route path= "/" element ={<Regester/>}/>
      }
     {isloged? <Route path ="/home" element ={<Home/>}/>:(
      <Route>
      <Route path="/home/print" element ={<Printing/>}></Route>
      <Route path ="/home/userprint" element ={<Userprinter {...mydata}/>}/>
      </Route>
      )

      }

</Routes>

</BrowserRouter>
</Myprovider>
  );
}

export default App;
