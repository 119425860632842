import React from 'react'
import { Usegetvalue } from '../contexrt/Myprovider'
import Listmilkile from '../milkiilayasha/Listmilkile'
import "./rasulmal.css"
const Rasulmal = () => {
  const inistat =Usegetvalue()
   const xisaab = inistat.xisaabhoredata
   console.log("rasulmaalkii hore ",xisaab)

   return (
    
<div className='rasulmal-container'>
 
    <div className='form-rasulmal'> 
      <form>
        <div className="form-card">

       <div className='form-column'>
         <div className='form-row'>
             <label>Rasul Maal</label>
             <input id="text" type="number" required  placeholder='rasulmaal kaaga' name="ras_hore"  onChange={inistat.raslumlalfunc}/>
        </div>

          <div className='form-row'>
             <label style={{marginTop:'10px'}}>Lacagta Hadeer</label>
             <input type="number" required placeholder='rasul mal danbe' name="ras_danbe" onChange={inistat.raslumlalfunc}/>
          </div>
          
          <div className='form-row'>
             <label>Inta ku darsantay</label>
             <input type="number" placeholder='inta uu kordhay' name="ras_korka"  readOnly value={inistat.korka}/>
          </div>
        


        
          <div className='form-row'>
           <div>
             <label>Boqolkiiba %</label>
             <input type="nunber" placeholder='boqolkiiba %' name="ras_boqolkiiiba" readOnly value={inistat.boqolkiba}/>
          </div>

          </div>

         </div>


         </div>
{/* halkaan waxaa ku dhamaada formk dhanka bidix  */}
 <div className="form-card">
        <div className='form-column'>
           <div className='form-row'>
              <label>qarashaad ka  </label>
              <input type="number" placeholder='lacagta guud' name="qarash"  onChange={inistat.qarshdeynfun}/>
           </div>
          
           <div className='form-row'>
             <label>Lacag lacelin rabo </label>
              <input type="number" placeholder='lacag-lacelinaayo' name="celis"  onChange={inistat.qarshdeynfun}/>
           </div>

           <div className='form-row'>
            <h3>total</h3><span>$ {inistat.totalqarshdeyn}</span>
           </div>


          </div>
   </div>

   <div className="form-card">
            <div className='form-column'>
               <div className='form-row' style={{background:'white'}}>
                      <label> xisaab bilow </label>
                       <input type="number" placeholder='intaa qarsh/deyn la bixin' name="lacagsako" readOnly value={inistat.raslmaldanbe}/>
          
               <span style={{color:'white'}}> - </span>
            </div>

          <div className='form-row'>
             <label> qarash / deyn celin kadib </label>
             <input type="number" placeholder='deyn/qarsh laan ' name="deynkadib" readOnly value={inistat.deynkadib}/>
          <span style={{color:'white'}}> = </span>
          </div>

          <div className='form-row'>
             <label>lacagta lasakeynayo</label>
             <input type="number" placeholder='lacatga sako laaan ah' name="ras_sako" readOnly value={inistat.sako}/>
          </div>
          
          </div>
        
       
         </div>

          <div className="form-card">
            <div className='form-column'>
               <div className='form-row' style={{background:'white'}}>
             <label>lacagta lasakeynayo</label>
             <input type="number" placeholder='qashar' name="lacag_sako" readOnly value={inistat.deynkadib}/>
          
             <span style={{color:'white'}}> - </span>
          </div>
          <div>
             <label> sakada maalka  </label>
             <input type="number" placeholder='Zakada malka' name="ras_sako" readOnly value={inistat.sako}/>
          <span style={{color:'white'}}> = </span>
          </div>

          <div>
             <label>lacagta oo nadiif ah </label>
             <input type="number" placeholder='lacgta oo nadiif ah' name="lacagtanadiifah" readOnly value={inistat.lacagtanadiifah}/>
          </div>
          </div>
          </div>


          
  </form>
<div className='net-profit'>

                <h3> net profit </h3>
  <div style={{display:'flex'}}>
  <div>
            
              <label>lacagta oo nadiif ah </label>
             <input type="number" placeholder='Zakada malka' name="ras_sako" readOnly value={inistat.lacagtanadiifah}/>
  </div>

 <div>
             <label>lacgta maal galinta </label>
             <input type="number" placeholder='Zakada malka' name="ras_sako" readOnly value={inistat.rasulmal}/>
          </div>

          <div>
          <label> faa iido nadiif ah </label>
             <input type="number" placeholder='Zakada malka' name="ras_sako" readOnly value={inistat.faaiido}/>
          </div>
          <div>
             <label> kororka boqolkiiba </label>
             <input type="number" placeholder='Zakada malka' name="ras_sako" readOnly value={inistat.finalboqolkiiba}/>
          </div>


  </div>
</div>
  <div>
    <button onClick={inistat.rasulmalfun}>ku dar xisaabta</button>
    <button>tir tir  xisaabta</button>

  </div>
{/* <table>
<thead>
  <tr>
<th>lacatii sanidkii hore</th>
<th>lacagt sanadkaan</th>
<th>lacgta siyaday</th>
<th>boqolkiiba</th>
<th>sako</th>
<th>qarash sanadkan</th>
<th>lacagta cusub</th>
</tr>
</thead>
<tbody>
<tr>
<td>{xisaab[0]}</td>
<td>{xisaab[1]}</td>
<td>{xisaab[2]}</td>
<td>{xisaab[3]}</td>
<td>{xisaab[4]}</td>
<td>00</td>
<td>{xisaab[5]}</td>

</tr>

</tbody>
  </table> */}
     

</div>      
    </div>
  )
}

export default Rasulmal