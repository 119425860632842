import React from 'react'

const Footer = () => {
  return (
    <div>
      <h3> xisaabtaan waxaa ka heli kartaa computerka</h3>
       <div className='saxiixa'>
        <p> Saxiixa xisaabiyaha </p> <span> .......................</span>
      </div>
    </div>
  )
}

export default Footer