import React from 'react'
import { Link } from 'react-router-dom'
import { Usegetvalue } from '../contexrt/Myprovider'
import Home from '../Home'
import './login.css'
const Login = () => {
    const login = Usegetvalue()
  return (
    <div className='container'>

 <div className='form-container'>
   <form >
   <div className='form-divs'>
    <label>Magaca</label>
    <input type ='text' placeholder ='magacaaga ' required />
    </div>
    <div className='form-divs'>
    <label>Password </label>
    <input type ='text' placeholder ='magacaaga ' required /> 
    </div>

    <button onClick={login.login}>
        <Link to ='/home'>Login </Link>
   </button>
   </form>
  
   </div>
    </div>
  )
}

export default Login