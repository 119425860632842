import React, { useEffect, useState } from 'react'
import axios from "axios"

const api = axios.create({
    baseURL:'http://localhost:3001/'
})

const Server = ({datatobesaved}) => {
  const [datas,setDatas]= useState([datatobesaved])
   
    const fetching =async ()=>{
       await api.post('/api',datatobesaved).then((res)=>{
        //setDatas(res.data)
        console.log('posted data is ',res.data)
       }
        )
        .catch((erro)=>{
            console.log(" there is an error",erro)
        })

    }
    useEffect(()=>{
         fetching()
    },[datas])
  return (
    
    <div>
      <ul>
      {datas.map((item,index)=>{
          return <li key ={index}> id {item.id} bono number {item.BonoNbr}  taariikh{item.Taariikh} wadarta {item.Wadarta} bixiyey {item.Bixiyey}</li>
    })}
    
    </ul>
    </div>
  )
}

export default Server



