import React from 'react'

import "./milkiil.css"
import { useContext } from 'react'
import { Usegetvalue } from '../contexrt/Myprovider'
export const Milkiilayasha = () => {
    const inistat =Usegetvalue();
  return (
    <div className='miilkile-contaner'>

<div className='milkile-form'>

<form>
 
<input type="text"   required placeholder=' magacaaga' name="magaca" onChange={inistat.qeybtintafaidafunc} />
<input type="number" required placeholder='lacagtiisa' name="rasmal_qof" onChange={inistat.qeybtintafaidafunc}/>
<input type="number" placeholder='inta ay korortay' value={inistat.qofboliiba} name="rasulmal" readOnly/>
<input type="number"  placeholder='Kordhid' value={inistat.instasiyaday} readOnly/>
<input type="number" required placeholder='intuu isticmalay' name="isticmal" onChange={inistat.qeybtintafaidafunc}/>
<input type="number"   placeholder='zako' name="sako_qof" value ={inistat.sako_qof} readOnly/>
<input type="number"  placeholder='xisaabta cusub' name="rasulmal" value={inistat.korosandle} readOnly/>
<button onClick={inistat.kudarmilkile}> ku dar </button>
</form>


</div>
    </div>
  )
}
