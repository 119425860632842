import React, { useRef } from 'react'
import { useReactToPrint } from 'react-to-print';
import PrinterHeader from '../headfoot/PrinterHeader';
import { Usegetvalue } from '../contexrt/Myprovider';
import "./printer.css"
import Footer from '../headfoot/Footer';

const Printing= () => {
    const malkiilelist =Usegetvalue();
    console.log("insied printing ")
    const conpentRef= useRef()
    const handeprint = useReactToPrint({
        content:()=>conpentRef.current,
        documenTitle: "file to pring",
        onafterPrint:()=>alert("print wilbe started ")
    });
  return (
    
    <div>
      <section>
            
      </section>
      <section>
       <div className='Listmilkile'  ref ={conpentRef}> 
       <PrinterHeader/>
      <table>
     
     <thead>
     <tr>
         <td>magaca</td>
         <td>lacagta</td>
         <td>korarka</td>
         <td>isticmal</td>
         <td>zako</td>
         <td>rasulmalka cusub</td>
     </tr>
     </thead>
     <tbody>
     { malkiilelist.milkilelist.map((sharelist,index)=>{
     // {magaca,rasl_hore,instasiyaday,sako_qof,istcaml,korosandle}
     return <tr key ={index}> 
        <td className='td-data'>{sharelist.magaca}</td>
         <td className='td-data'>{sharelist.raslqof}</td>
         <td className='td-data'>{sharelist.instasiyaday}</td>
         <td className='td-data'>{sharelist.isticmal}</td>
         <td className='td-data'>{sharelist.sako_qof}</td>
         <td className='td-data'>{sharelist.korosandle}</td>
     </tr>
     
       }
       )
     }
     
     
     
     </tbody>
         </table>
     
         <div className='milkile-total' style={{display:"flex",justifyContent:"space-between",width:'70%'}}>
        
      <h2> total </h2> <span>$ 
       
       
       {
         malkiilelist.milkilelist.length===0? 
          "000.0"
         :malkiilelist.milkilelist.reduce((total,item)=>{
             return total=total+parseFloat(item.korosandle)
         },0.0)
       
       }  </span>
     
      
         </div>

         <section className='footer'>
  <Footer/>

         </section>
        </div>
         <button onClick={handeprint}> print your </button>  
       
         </section>
       </div>
             
  )
}

export default Printing;