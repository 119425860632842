import React from 'react'

const PrinterHeader = () => {
  return (
    <div className='log-top'>
    <h2> xisaab xirta kasanda 2021-2022 </h2> 
    <h3> somali ayaa leh </h3>

</div>
  )
}

export default PrinterHeader