import './home.css';
import { useEffect, useState } from 'react';
import Myprovider from './contexrt/Myprovider';

import Rasulmal from './rasulmal/Rasulmal';
import { Milkiilayasha } from './milkiilayasha/Milkiilayasha';
import Listmilkile from './milkiilayasha/Listmilkile';
import Sanadanbe from './xisabsanddanbe/Sanadanbe';
import Server from './Server';
import {BrowserRouter,Routes,Route } from 'react-router-dom';
import Login from './printfolder/Userprinter';
import Printing from './printfolder/Printing';
import SendMessages from './whatsapp/Whatsapp';


const  Home =() =>{
  /* const [saved,setSaved] = useState(false);
  const [raslumal,setRasulmal]=useState(1)
  const [raslmaldanbe,setRsmdanbe]=useState(0);
  const [korka,setKorar]=useState(1);
  const [boqolkiba,setMsetBoqolkiiba]=useState(1);
  const [sako,setSako]=useState(0)
  const [rasmalsakokadib,setKakokadib]=useState(0)
// qeybint handitada
const [magaca,setMagaca]=useState()
const [rasl_hore,setRasulHore]=useState(1)
const [isticmal,setIsticmal]=useState(0);
const [instasiyaday,setIntasiyada]=useState(0);
const [sako_qof,setSkoqof]= useState(1);
  const [milkilelist,setMililelist]= useState([])
  const [korosandle,setKororsandle]=useState(0)
 */
/*  ras_hore
    ras_danbe
    ras_korka
    ras_boqolkiiiba
    ras_sako
    ras_guud
 */
/* 
const raslumlalfunc=(e)=>{
  e.preventDefault();
  
  if(e.target.name ==='ras_hore')
     setRasulmal(parseInt(e.target.value))
  
  if(e.target.name==='ras_danbe')
       setRsmdanbe(parseInt(e.target.value))

  console.log(" RASLUM HORE ",e.target.value)
}


const qeybtintafaidafunc=(e)=>{
  e.preventDefault();

  if(e.target.name ==='magaca')
     setMagaca(e.target.value)
  
  if(e.target.name==='rasl_hore')
       setRasulHore(parseInt(e.target.value))

  if(e.target.name==='isticmal')
     setIsticmal(parseInt(e.target.value))

 
  
  console.log("sko qof",e.target.value)
 

}
useEffect(()=>{
  let result =parseFloat(raslmaldanbe-raslumal);
  
  console.log(" name is ", raslmaldanbe-raslumal)
  let koordhid = (result/raslumal)*100;
  setKorar(result)
  setMsetBoqolkiiba(parseFloat(koordhid).toFixed(2))
  setSako((parseFloat(raslmaldanbe)*0.01).toFixed(2))

  setKakokadib((parseFloat(raslmaldanbe)-parseFloat(sako)).toFixed(2))

 
},[raslmaldanbe,raslumal])

useEffect(()=>{

  
  let koordh = (boqolkiba*rasl_hore)/100;
  
 
  setIntasiyada(parseFloat(koordh).toFixed(2))
  let ralmalcusub = parseFloat(koordh)+parseFloat(rasl_hore);
  setSkoqof(parseFloat(ralmalcusub*0.01).toFixed(2))
 
  setKakokadib(parseFloat(raslmaldanbe)-parseFloat(sako).toFixed(2))
  let kororsandle  = parseFloat(ralmalcusub) - (parseFloat(sako_qof)+ parseFloat(isticmal));
  setKororsandle(kororsandle.toFixed(2))

 setSaved(false)
 
},[rasl_hore,isticmal,raslmaldanbe])

// hanltiilataasha 
const kudarmilkile=(e)=>{
 e.preventDefault();

 setMililelist([...milkilelist, {magaca,rasl_hore,instasiyaday,sako_qof,isticmal,korosandle,iskatirtir,waxkabedel}])
  
  setKakokadib(110)
  setMagaca()
  setIsticmal(110)
  setSkoqof(110)
  setRasulHore(110)
  setIntasiyada(110)
  setKororsandle(110)
}

const iskatirtir=(id=0)=>{

  console.log(" ited to bed delted index is ",id)

}
const waxkabedel =(id)=>{
  console.log(" ited to bed delted index is ",id)

}
// waa keyding to msysql 
const keydi=()=>{

setSaved(true)
  }
// state 
const initilastate ={
rasulmal:raslumal,
raslmaldanbe:raslmaldanbe,
korka:korka,
boqolkiba:boqolkiba,
sako:sako,

rasmalsakokadib:rasmalsakokadib,
milkiile:'',

magaca:magaca,
rasl_hore:rasl_hore,
isticmal:isticmal,
sako_qof:sako_qof,
instasiyaday:instasiyaday,
korosandle:korosandle,
milkilelist:milkilelist,
raslumlalfunc:raslumlalfunc,
qeybtintafaidafunc:qeybtintafaidafunc,
kudarmilkile:kudarmilkile,
iskatirtir:iskatirtir,
waxkabedel:waxkabedel,
keydi:keydi
} */

  return (
    <div className="App">
     <h1>xisaabinta dukaamada </h1>
     
     {/*<Myprovider inistates={initilastate}> */}
<section>
  <h3> Lacagta sanidkuu hore lagu <br/>shaqeeyey iyo waxa ku hordhay</h3>
<Rasulmal/>

</section>
     <section>
      <h3> qeybinta lacagta sanadkanan <br/>iyo wixii la faaiidya </h3>
     <Milkiilayasha/>
     <Listmilkile/>
     </section>
     <section>
     <h3> lacagata sanada soo socod lagu <br/> xisaabtamid doono insha allah</h3>
         
         {/* <Sanadanbe/> */}

     </section>
    {/*</div> </Myprovider> */}

     <section>
        {/* {saved && <Server datatobesaved={milkilelist}/>} */}

    
    
     </section>
    </div>
  );
}

export default Home;